import { I18n } from "react-polyglot-hooks"
import NotFoundScene from "scenes/NotFoundScene"
import { useRouter } from "next/router"
import { ALLOWED_LOCALES } from "helpers/constants"

import common_es from "../i18n/es/common"
import not_found_es from "../i18n/es/not_found"
import landings_es from "../i18n/es/landings"

import common_pt from "../i18n/pt/common"
import not_found_pt from "../i18n/pt/not_found"
import landings_pt from "../i18n/pt/landings"

import common_it from "../i18n/it/common"
import not_found_it from "../i18n/it/not_found"
import landings_it from "../i18n/it/landings"

const phrases = {
  es: { ...common_es, ...not_found_es, ...landings_es },
  pt: { ...common_pt, ...not_found_pt, ...landings_pt },
  it: { ...common_it, ...not_found_it, ...landings_it }
}

export default function NotFound() {
  const router = useRouter()
  const locale = router.asPath.split("/")[1]

  return (
    <I18n locale={ALLOWED_LOCALES.includes(locale) ? locale : "es"} phrases={phrases[locale] || phrases.es}>
      <NotFoundScene />
    </I18n>
  )
}
